import './headerStyle.scss';
import { Link } from 'react-router-dom';
const logo = (
	<svg
		className='header-logo'
		width='154'
		height='38'
		viewBox='0 0 154 38'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M114.919 0.916992V23.5779H106.826L96.585 10.1027V23.5779H88.9482V0.916992H97.0264L107.282 14.4219V0.916992H114.919Z'
			fill='black'
		/>
		<path
			d='M56.7239 9.27443V23.6668H49.0724V20.5901C48.219 21.951 45.4233 24.2585 40.3174 24.2585C35.0056 24.2585 30.9297 22.1285 30.9297 17.6761C30.9297 12.8984 33.2251 8.66797 49.0724 9.82173V9.25964C49.0724 7.04088 48.5133 5.11795 43.9371 5.11795C40.1997 5.11795 39.1844 6.36046 38.8754 7.81005H32.0333C32.666 2.21877 37.3893 0.251465 43.9224 0.251465C50.4409 0.266257 56.7239 1.6271 56.7239 9.27443ZM49.0872 14.1853C41.9066 13.8451 38.3751 14.1409 38.3751 17.3507C38.3751 18.9778 39.8171 19.9984 42.451 19.9984C45.4674 19.9984 47.807 18.6376 49.0724 16.833V14.1853H49.0872Z'
			fill='black'
		/>
		<path
			d='M86.1813 16.4483C86.1813 21.9508 81.1196 24.2583 72.5411 24.2583C63.4477 24.2583 58.9893 21.5219 58.9893 16.2708L67.0674 16.3152C67.0674 18.8741 69.7896 19.5546 73.012 19.5546C76.4993 19.5546 78.1473 18.9629 78.1473 17.3358C78.1473 15.2797 74.3216 15.2058 69.3923 14.4366C63.6096 13.5343 59.6661 12.4693 59.6661 7.8247C59.6661 3.12092 63.8303 0.266113 72.1586 0.266113C81.3697 0.266113 85.0336 3.54988 85.0336 7.8247L77.2497 7.78032C77.2497 6.28635 75.4693 4.92551 72.0703 4.92551C68.7154 4.92551 67.2293 5.73906 67.2293 7.18865C67.2293 8.81574 70.5841 9.11158 74.454 9.62929C80.2661 10.3393 86.1813 10.6351 86.1813 16.4483Z'
			fill='black'
		/>
		<path
			d='M125.616 0.902283H117.965V23.6668H125.616V0.902283Z'
			fill='black'
		/>
		<path
			d='M29.1198 7.85432H19.32V23.6667H10.8593V7.85432H1.05957V0.916992H29.1198V7.85432Z'
			fill='black'
		/>
		<path
			d='M137.285 6.98161V10.7387H151.72V16.8033H137.285V23.6667H128.839V0.916992H152.941V6.98161H137.285Z'
			fill='black'
		/>
		<path
			d='M29.8549 28.06V30.5154H29.7666C29.4429 30.3823 28.8102 30.2195 28.0009 30.2195C25.8085 30.2195 24.9992 31.5952 24.9992 33.0596V37.4823H22.3506V28.1487H24.9992V29.8645C25.3817 29.1989 26.4853 27.9268 28.4717 27.9268C29.075 27.912 29.5312 27.9712 29.8549 28.06Z'
			fill='black'
		/>
		<path
			d='M41.6267 32.808C41.6267 36.3284 38.8015 37.7336 36.0941 37.7336C33.4014 37.7336 30.5615 36.3284 30.5615 32.808C30.5615 29.2875 33.4014 27.8823 36.0941 27.8823C38.8015 27.8971 41.6267 29.3023 41.6267 32.808ZM39.1547 32.808C39.1547 31.1661 38.316 29.7165 36.0941 29.7165C33.8722 29.7165 33.0482 31.1661 33.0482 32.808C33.0482 34.4351 33.8722 35.8847 36.0941 35.8847C38.316 35.8994 39.1547 34.4351 39.1547 32.808Z'
			fill='black'
		/>
		<path
			d='M43.0391 34.3167V28.1338H45.6876V33.6511C45.6876 34.7753 46.3351 35.7367 47.9684 35.7367C49.6016 35.7367 50.3374 34.7457 50.3374 33.6511V28.1338H52.9859V37.4674H50.3374V36.1509C49.9401 36.713 48.8071 37.7336 46.8206 37.7336C44.4369 37.7336 43.0391 36.4172 43.0391 34.3167Z'
			fill='black'
		/>
		<path
			d='M15.2732 37.6598C12.2127 37.6598 9.21094 36.3285 9.21094 32.675C9.21094 29.0066 12.2862 27.6754 15.0378 27.6754C17.5245 27.6754 19.9671 28.7108 20.5851 31.0775H17.7894C17.3921 30.1752 16.3915 29.6131 15.0378 29.6131C13.0072 29.6131 11.9772 30.7964 11.9772 32.6898C11.9772 34.5831 13.1838 35.7516 15.2585 35.7516C16.8624 35.7516 17.7452 34.9973 18.0101 33.9914H15.0084V31.9058H20.7911C21.2325 35.87 18.6428 37.6598 15.2732 37.6598Z'
			fill='black'
		/>
		<path
			d='M66.9206 31.5952C66.744 29.3764 65.199 27.912 62.0648 27.912H54.5605V31.6396V31.7579V37.5563H57.371V35.7665H61.7411C65.1107 35.7665 66.9206 34.4056 66.9353 31.6396V31.5952H66.9206ZM61.5793 33.6217H57.371V31.7431V31.6248V30.0716H61.5793C63.0213 30.0716 63.8747 30.5302 64.0513 31.5804V31.61C64.0366 32.9708 63.1978 33.6217 61.5793 33.6217Z'
			fill='black'
		/>
	</svg>
);

const logoWhite = (
	<svg
		className='header-logo'
		width='154'
		height='38'
		viewBox='0 0 154 38'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M114.919 0.917114V23.5781H106.826L96.585 10.1028V23.5781H88.9482V0.917114H97.0264L107.282 14.422V0.917114H114.919Z'
			fill='white'
		/>
		<path
			d='M56.7239 9.27443V23.6668H49.0724V20.5901C48.219 21.951 45.4233 24.2585 40.3174 24.2585C35.0056 24.2585 30.9297 22.1285 30.9297 17.6761C30.9297 12.8984 33.2251 8.66797 49.0724 9.82173V9.25964C49.0724 7.04088 48.5133 5.11795 43.9371 5.11795C40.1997 5.11795 39.1844 6.36046 38.8754 7.81005H32.0333C32.666 2.21877 37.3893 0.251465 43.9224 0.251465C50.4409 0.266257 56.7239 1.6271 56.7239 9.27443ZM49.0872 14.1853C41.9066 13.8451 38.3751 14.1409 38.3751 17.3507C38.3751 18.9778 39.8171 19.9984 42.451 19.9984C45.4674 19.9984 47.807 18.6376 49.0724 16.833V14.1853H49.0872Z'
			fill='white'
		/>
		<path
			d='M86.1813 16.4484C86.1813 21.9509 81.1196 24.2585 72.5411 24.2585C63.4477 24.2585 58.9893 21.522 58.9893 16.2709L67.0674 16.3153C67.0674 18.8743 69.7896 19.5547 73.012 19.5547C76.4993 19.5547 78.1473 18.963 78.1473 17.3359C78.1473 15.2799 74.3216 15.2059 69.3923 14.4367C63.6096 13.5344 59.6661 12.4694 59.6661 7.82482C59.6661 3.12104 63.8303 0.266235 72.1586 0.266235C81.3697 0.266235 85.0336 3.55 85.0336 7.82482L77.2497 7.78044C77.2497 6.28648 75.4693 4.92564 72.0703 4.92564C68.7154 4.92564 67.2293 5.73918 67.2293 7.18877C67.2293 8.81587 70.5841 9.1117 74.454 9.62941C80.2661 10.3394 86.1813 10.6352 86.1813 16.4484Z'
			fill='white'
		/>
		<path
			d='M125.616 0.902283H117.965V23.6668H125.616V0.902283Z'
			fill='white'
		/>
		<path
			d='M29.1198 7.85444H19.32V23.6668H10.8593V7.85444H1.05957V0.917114H29.1198V7.85444Z'
			fill='white'
		/>
		<path
			d='M137.285 6.98173V10.7388H151.72V16.8035H137.285V23.6668H128.839V0.917114H152.941V6.98173H137.285Z'
			fill='white'
		/>
		<path
			d='M29.8549 28.06V30.5154H29.7666C29.4429 30.3823 28.8102 30.2195 28.0009 30.2195C25.8085 30.2195 24.9992 31.5952 24.9992 33.0596V37.4823H22.3506V28.1487H24.9992V29.8645C25.3817 29.1989 26.4853 27.9268 28.4717 27.9268C29.075 27.912 29.5312 27.9712 29.8549 28.06Z'
			fill='white'
		/>
		<path
			d='M41.6267 32.8081C41.6267 36.3285 38.8015 37.7337 36.0941 37.7337C33.4014 37.7337 30.5615 36.3285 30.5615 32.8081C30.5615 29.2877 33.4014 27.8824 36.0941 27.8824C38.8015 27.8972 41.6267 29.3025 41.6267 32.8081ZM39.1547 32.8081C39.1547 31.1662 38.316 29.7166 36.0941 29.7166C33.8722 29.7166 33.0482 31.1662 33.0482 32.8081C33.0482 34.4352 33.8722 35.8848 36.0941 35.8848C38.316 35.8996 39.1547 34.4352 39.1547 32.8081Z'
			fill='white'
		/>
		<path
			d='M43.0391 34.3169V28.1339H45.6876V33.6512C45.6876 34.7754 46.3351 35.7369 47.9684 35.7369C49.6016 35.7369 50.3374 34.7458 50.3374 33.6512V28.1339H52.9859V37.4675H50.3374V36.151C49.9401 36.7131 48.8071 37.7338 46.8206 37.7338C44.4369 37.7338 43.0391 36.4173 43.0391 34.3169Z'
			fill='white'
		/>
		<path
			d='M15.2732 37.6598C12.2127 37.6598 9.21094 36.3285 9.21094 32.675C9.21094 29.0066 12.2862 27.6754 15.0378 27.6754C17.5245 27.6754 19.9671 28.7108 20.5851 31.0775H17.7894C17.3921 30.1752 16.3915 29.6131 15.0378 29.6131C13.0072 29.6131 11.9772 30.7964 11.9772 32.6898C11.9772 34.5831 13.1838 35.7516 15.2585 35.7516C16.8624 35.7516 17.7452 34.9973 18.0101 33.9914H15.0084V31.9058H20.7911C21.2325 35.87 18.6428 37.6598 15.2732 37.6598Z'
			fill='white'
		/>
		<path
			d='M66.9206 31.5952C66.744 29.3764 65.199 27.912 62.0648 27.912H54.5605V31.6396V31.7579V37.5563H57.371V35.7665H61.7411C65.1107 35.7665 66.9206 34.4056 66.9353 31.6396V31.5952H66.9206ZM61.5793 33.6217H57.371V31.7431V31.6248V30.0716H61.5793C63.0213 30.0716 63.8747 30.5302 64.0513 31.5804V31.61C64.0366 32.9708 63.1978 33.6217 61.5793 33.6217Z'
			fill='white'
		/>
	</svg>
);
const Header = ({ isHeaderDark }) => {
	const deleteHandler = () => {
		const toggleElement = document.querySelector('.mobile-header__head');
		const mobileHeader = document.querySelector('.mobile-header');
		toggleElement.classList.remove('active');
		mobileHeader.classList.remove('active');   
	};

	return (
		<header className='header' id='header'>
			<div className='mobile-header'>
				<div className='mobile-header__head'>
					<div className='logo-mobile'>
						<img
							src={logoWhite}
							alt='tasnif group'
							width='154'
							height='38'
						/>
					</div>
					<div className='menu-mobile'>
						<svg
							onClick={() => deleteHandler()}
							width='25'
							height='25'
							viewBox='0 0 25 25'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M10.2234 12.2143L0 25H4.0378L12.3282 14.7143L20.5756 25H25L14.7766 12.2143L24.3557 0H20.3179L12.6289 9.71429L5.02577 0H0.64433L10.2234 12.2143Z'
								fill='white'
							/>
						</svg>
					</div>
				</div>

				<div className='mobile-header__main'>
					<div className='header__menu-item-mobile'>
						<Link to='/' onClick={() => deleteHandler()}>
							Bosh sahifa
						</Link>
					</div>
					<div className='header__menu-item-mobile'>
						<Link to='/portfolio' onClick={() => deleteHandler()}>
							Portfolio
						</Link>
					</div>
					<div className='header__menu-item-mobile'>
						<Link to='/about' onClick={() => deleteHandler()}>
							Biz haqimizda
						</Link>
					</div>
					<div className='header__menu-item-mobile'>
						<Link to='school' onClick={() => deleteHandler()}>
							Tasnif maktab
						</Link>
					</div>
					<div className='header__menu-item-mobile'>
						<Link to='/contact' onClick={() => deleteHandler()}>
							Contact
						</Link>
					</div>
				</div>
			</div>
			<div className='d-flex  header__content w-100'>
				<div className='header__logo'>
					<Link to='/'>
						<div className='cursor'></div>
						<div
							style={{ width: '8vw' }}
							className='header-logo-none'></div>

						{!isHeaderDark ? logo : logoWhite}
					</Link>
				</div>

				<div className='header__menu d-flex '>
					<div className='header__menu-item'>
						<Link
							style={
								isHeaderDark
									? { color: '#fff' }
									: { color: 'rgba(59, 59, 59, 1)' }
							}
							to='/'>
							Bosh sahifa
						</Link>
					</div>
					<div className='header__menu-item'>
						<Link
							style={
								isHeaderDark
									? { color: '#fff' }
									: { color: 'rgba(59, 59, 59, 1)' }
							}
							to='/portfolio'>
							Portfolio
						</Link>
					</div>
					<div className='header__menu-item'>
						<Link
							style={
								isHeaderDark
									? { color: '#fff' }
									: { color: 'rgba(59, 59, 59, 1)' }
							}
							to='/about'>
							Biz haqimizda
						</Link>
					</div>

					<div className='header__menu-item'>
						<Link
							style={
								isHeaderDark
									? { color: '#fff' }
									: { color: 'rgba(59, 59, 59, 1)' }
							}
							to='/contact'>
							Contact
						</Link>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
