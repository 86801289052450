import './ProjectScrollStyle.scss';
import { useState, useEffect } from 'react';
import { ProektScrollApi } from '../../api/proektApi';
import ProjectCard from '../ProjectCard/ProjectCard';
const ProjectScroll = () => {
	const [smallProjects, setSmallProjects] = useState([]),
		[largeProjects, setLargeProjects] = useState([]);

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		ProektScrollApi.getProjects().then((res) => {
			let datas = res.data;
			let small = [];
			let large = [];
			datas.reverse();

			datas.forEach((data) => {
				if (data.types === 'small') {
					small.push(data);
				}
				if (data.types === 'large') {
					large.push(data);
				}
			});

			setSmallProjects(small);
			setLargeProjects(large);
			setLoading(false);
		});
	}, []);
	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div className='projects_screen__main  custom-cursor4'>
			<div className='project-element '>
				<ProjectCard data={smallProjects[0]} />
				<ProjectCard data={smallProjects[3]} />
				<ProjectCard data={smallProjects[6]} />

				<ProjectCard data={smallProjects[1]} />
				<ProjectCard data={smallProjects[4]} />
				<ProjectCard data={smallProjects[7]} />

				<ProjectCard data={smallProjects[2]} />
				<ProjectCard data={smallProjects[5]} />
				<ProjectCard data={smallProjects[8]} />
			</div>
		</div>
	);
};

export default ProjectScroll;
