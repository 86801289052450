import { Link } from 'react-router-dom';
import './footerStyle.scss';
import logoWhite from '../../assets/images/logoWhite.png';
import logoBlack from '../../assets/images/tasnifLogoDark.png';
import { ArrowLeft, ArrowLeftDark } from '../../assets/icons';
import { ContactAPi } from '../../api/contactApi';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const Footer = ({ isDarkMode, isScroll }) => {
	const router = useHistory();

	const [email, setEmail] = useState('');
	const history = useHistory();
	let footerStyle = {
		backgroundColor: !isDarkMode ? '#222222' : '#fff',
		color: !isDarkMode ? '#fff' : '#000',
	};

	const aStyle = {
		color: !isDarkMode ? '#fff' : '#000',
	};
	const inputStyle = {
		color: !isDarkMode ? '#fff' : '#000',
		borderBottom: !isDarkMode
			? '1px solid #fff'
			: '1px solid rgba(34, 34, 34, 1)',
	};
	let footerEndStyle = {
		backgroundColor: !isDarkMode ? '#000' : 'rgba(244, 244, 244, 1)',
		color: !isDarkMode ? '#fff' : 'rgba(170, 170, 170, 1)',
		marginBottom: !isDarkMode ? '0' : '15vh',
	};

	if (router.location.pathname === '/about') {
		footerEndStyle = {
			backgroundColor: !isDarkMode ? '#000' : 'rgba(244, 244, 244, 1)',
			color: !isDarkMode ? '#fff' : 'rgba(170, 170, 170, 1)',
			marginBottom: '0',
		};
	}

	const SubmitHandler = (e) => {
		e.preventDefault();
		const data = {
			name: "faqat email jo'natildi",
			company: 'no',
			email: email,
			message: 'nomessage',
		};

		ContactAPi.postContact(data).then((res) => {
			history.push('/');
		});
	};
	return (
		<footer className='footer' style={footerStyle}>
			<div className='main-container'>
				<div className='row'>
					<div style={{ height: '10vh' }} className='logo col-5'>
						<div>
							<img
								id='footer-logo'
								src={isDarkMode ? logoBlack : logoWhite}
								alt=''
							/>
						</div>
					</div>
					<div className='col-2'></div>
					<div
						style={{ height: '10vh' }}
						className='footer__section2-text col-5'>
						<h3>
							Siz bilan hamkorlikdan hamisha mamnunmiz. <br />
							Let’s connect :)
						</h3>
					</div>
				</div>
				<div className='row'>
					<div className='footer_section-email col-5'>
						<div>
							<h4 style={aStyle}>Biz bilan aloqada bo'l!</h4>
						</div>
						<div className='newsletter-form'>
							<form onSubmit={SubmitHandler}>
								<input
									style={inputStyle}
									type='email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder='Email Address'
									required=''
								/>
								<button
									type='submit'
									name='Submit'
									value='Submit'
									className='footer-button'>
									<span>
										{!isDarkMode
											? ArrowLeft
											: ArrowLeftDark}
									</span>
								</button>
							</form>
						</div>
					</div>
					<div className='col-2'></div>
					<div className='footer__section2-links col-5'>
						<div>
							<h4>IJTIMOIY TARMOQLAR</h4>
							<ul>
								<li>
									<a
										style={aStyle}
										href='https://www.instagram.com/tasnifgroup'>
										Instagram
									</a>
								</li>
								<li>
									<a
										style={aStyle}
										href='https://t.me/tasnifgroup'>
										Telegram
									</a>
								</li>
								<li>
									<a
										style={aStyle}
										href='https://www.facebook.com/tasnifgroup'>
										Facebook
									</a>
								</li>
								<li>
									<a
										style={aStyle}
										href='https://twitter.com/tasnifgroup'>
										Twitter
									</a>
								</li>
							</ul>
						</div>
						<div style={{ marginLeft: '5vw' }}>
							<h4>XIZMATLARIMIZ</h4>
							<ul>
								<li>
									<Link style={aStyle} to='/portfolio'>
										Graphic design
									</Link>
								</li>
								<li>
									<Link style={aStyle} to='/portfolio'>
										2D motion
									</Link>
								</li>
								<li>
									<Link style={aStyle} to='/portfolio'>
										3D motion
									</Link>
								</li>
								<li>
									<Link style={aStyle} to='/portfolio'>
										Modeling
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-md-5'>
						<div className='footer_section-address'>
							<h2>
								Mirzo Ulug'bek tumani ,
								<br />
								Darxon 1-tor ko'chasi, 10
							</h2>
						</div>
					</div>
					<div className='col-2'></div>
					<div className='col-5'>
						<div className='contact-info d-flex'>
							<h2 style={{ textAlign: 'left' }}>
								tasnifgroup@gmail.com
								<br />
								893 804 16 66
							</h2>
						</div>
					</div>
				</div>
			</div>
			<div
				style={footerEndStyle}
				className='footer-end	w-100 container-fluid'>
				<div className='footer-end-div '>
					<p>TASNIF GROUP</p>
					<p className='title-2'>DIZAYNDA ISHONCHLI HAMKOR</p>
					<p>COPYWRITE {new Date().getFullYear()}</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
