import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { useState, useEffect } from 'react';
import Scrollbar from 'react-smooth-scrollbar';

import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import SmoothScrollbar from 'smooth-scrollbar';

import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import PortfolioPage from './pages/portfolio/portfolio';
import AboutPage from './pages/about/About';
import PortfolioDetailPage from './pages/portfolio/portfolioDetail/PortfolioDetailPage';
import RedirectPage from './pages/Redirect';
// import SchoolPage from './pages/school/SchoolPage';

import { PartnerApi } from './api/partnerApi';
import { CategoryApi } from './api/categoryApi';

import ScrollToTop from './components/gotoTop';
import MainLoader from './components/mainLoader/mainLoader';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Cursor from './components/cursor/MouseCursor';
import Cursor2 from './components/cursor/MouseCursor2.jsx';

import 'bootstrap/dist/css/bootstrap.min.css';
import './mainStyle.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

SmoothScrollbar.use(OverscrollPlugin);

function App() {
	const [isHeaderDark, setIsHeaderDark] = useState(true);
	const [mainLoad, setMainLoad] = useState(true);
	const [isScroll, setIsScroll] = useState(false);
	const [isDarkMode, setIsDarkMode] = useState(false);
	const [defaultKey, setDefaultKey] = useState('Graphic Design');
	const [category, setCategory] = useState([]);
	const [categoryGraphic, setCategoryGraphic] = useState([]);
	const [category2d, setCategory2d] = useState([]);
	const [category3d, setCategory3d] = useState([]);
	const [mainLoading, setMainLoading] = useState(true);
	const [categorySculpture, setCategorySculpture] = useState([]);
	const [partners, setPartners] = useState([]);
	const header = document.querySelector('header');
	const cursor = document.querySelector('#cursor-dot');
	const cursorOutline = document.querySelector('#cursor-dot-outline');

	const history = useHistory();
	const cursor2 = document.querySelector('#cursor-dot2');
	const cursorOutline2 = document.querySelector('#cursor-dot-outline2');

	const elements2 = document.querySelector('.custom-cursor2');
	const elements3 = document.querySelector('.custom-cursor3');
	const elements4 = document.querySelector('.custom-cursor4');
	const elements5 = document.querySelector('.custom-cursor5');
	const elements6 = document.querySelector('.custom-cursor6');
	const elements7 = document.querySelector('.custom-cursor7');

	elements2?.addEventListener('mouseover', function () {
		cursor.style.visibility = 'visible';
		cursorOutline.style.visibility = 'visible';
	});
	elements2?.addEventListener('mouseout', function () {
		cursor.style.visibility = 'hidden';
		cursorOutline.style.visibility = 'hidden';
	});
	elements3?.addEventListener('mouseover', function () {
		elements3.style.cursor = 'none';
		cursor.style.visibility = 'visible';
		cursorOutline.style.visibility = 'visible';
	});
	elements3?.addEventListener('mouseout', function () {
		elements3.style.cursor = 'pointer';
		cursor.style.visibility = 'hidden';
		cursorOutline.style.visibility = 'hidden';
	});
	elements4?.addEventListener('mouseout', function () {
		elements4.style.cursor = 'pointer';
		cursor.style.visibility = 'hidden';
		cursorOutline.style.visibility = 'hidden';
	});
	elements4?.addEventListener('mouseover', function () {
		elements4.style.cursor = 'none!important';
		cursor.style.visibility = 'visible';
		cursorOutline.style.visibility = 'visible';
	});

	elements5?.addEventListener('mouseout', function () {
		elements5.style.cursor = 'pointer';
		cursor2.style.visibility = 'hidden';
		cursorOutline2.style.visibility = 'hidden';
	});
	elements5?.addEventListener('mouseover', function () {
		elements5.style.cursor = 'none!important';
		cursor2.style.visibility = 'visible';
		cursorOutline2.style.visibility = 'visible';
	});

	elements6?.addEventListener('mouseout', function () {
		elements6.style.cursor = 'pointer';
		cursor.style.visibility = 'hidden';
		cursorOutline.style.visibility = 'hidden';
	});
	elements6?.addEventListener('mouseover', function () {
		elements6.style.cursor = 'none';

		cursor.style.visibility = 'visible';
		cursorOutline.style.visibility = 'visible';
		//
	});

	elements7?.addEventListener('mouseout', function () {
		elements7.style.cursor = 'pointer';
		cursor.style.visibility = 'hidden';
		cursorOutline.style.visibility = 'hidden';
	});
	elements7?.addEventListener('mouseover', function () {
		elements7.style.cursor = 'none!important';
		cursor.style.visibility = 'visible';
		cursorOutline.style.visibility = 'visible';
	});

	const headerChange = (e) => {
		let header = document.querySelector('header');
		let headerLogo = document.querySelector('.header-logo');
		let headerLogoDiv = document.querySelector('.header-logo-none');
		let scrollHeight = e.offset.y;
		const point2 = (3910 * e.limit.y) / 3918;
		if (scrollHeight > 0) {
			header.classList.add('header-remove');
			setIsHeaderDark(true);
		}
		if (e.offset.y > point2) {
			headerLogo.style.display = 'none';
			headerLogoDiv.style.display = 'block';
		} else {
			headerLogo.style.display = 'block';
			headerLogoDiv.style.display = 'none';
		}

		if (scrollHeight === 0) {
			header.classList.remove('header-remove');
			setIsHeaderDark(false);
		}
	};

	useEffect(() => {
		if (!mainLoad) setMainLoading(false);
		getDatas();
	}, [mainLoad]);

	window.addEventListener('scroll', () => {
		let header = document.querySelector('header');

		if (window.scrollY > 0) {
			header?.classList?.add('header-remove');
			setIsHeaderDark(true);
		}

		if (window.scrollY === 0) {
			header?.classList?.remove('header-remove');
			setIsHeaderDark(false);
		}
	});
	const getDatas = async () => {
		try {
			const res = await CategoryApi.getCategories();
			res.data.forEach((cat) => {
				console.log(cat);
				if (cat.name.toLowerCase() === 'graphic design') {
					setCategoryGraphic([
						{ image: cat.category_photo1 },
						{ image: cat.category_photo2 },
						{ image: cat.category_photo3 },
						{ image: cat.category_photo4 },
						{ image: cat.category_photo5 },
						{ image: cat.category_photo6 },
					]);
					setCategory([
						{ image: cat.category_photo1 },
						{ image: cat.category_photo2 },
						{ image: cat.category_photo3 },
						{ image: cat.category_photo4 },
						{ image: cat.category_photo5 },
						{ image: cat.category_photo6 },
					]);
				} else if (cat.name.toLowerCase() === '2d motion') {
					setCategory2d([
						{ image: cat.category_photo1 },
						{ image: cat.category_photo2 },
						{ image: cat.category_photo3 },
						{ image: cat.category_photo4 },
						{ image: cat.category_photo5 },
						{ image: cat.category_photo6 },
					]);
				} else if (cat.name.toLowerCase() === '3d motion') {
					setCategory3d([
						{ image: cat.category_photo1 },
						{ image: cat.category_photo2 },
						{ image: cat.category_photo3 },
						{ image: cat.category_photo4 },
						{ image: cat.category_photo5 },
						{ image: cat.category_photo6 },
					]);
				} else if (cat.name.toLowerCase() === 'sculpture') {
					setCategorySculpture([
						{ image: cat.category_photo1 },
						{ image: cat.category_photo2 },
						{ image: cat.category_photo3 },
						{ image: cat.category_photo4 },
						{ image: cat.category_photo5 },
						{ image: cat.category_photo6 },
					]);
				}
			});
			const res2 = await PartnerApi.getPartners();
			setPartners(res2.data);
			setMainLoading(false);
			setMainLoad(false);
		} catch (err) {
			setMainLoading(false);
			setMainLoad(false);
		}
	};
	if (mainLoading) {
		header?.classList.add('header-delete');
		// header?.classList.remove('header-remove');
	} else {
		header?.classList.remove('header-delete');
	}
	if (mainLoad) {
		return <MainLoader />;
	}

	return (
		<Router>
			<div className='App'>
				<Cursor />
				<Cursor2 />
				<ScrollToTop />
				<Header isHeaderDark={isHeaderDark} />

				<Switch>
					<Route exact path='/'>
						<Home
							setIsHeaderDark={setIsHeaderDark}
							isScroll={isScroll}
							category={category}
							setCategory={setCategory}
							categoryGraphic={categoryGraphic}
							category2d={category2d}
							category3d={category3d}
							categorySculpture={categorySculpture}
							partners={partners}
							mainLoad={mainLoad}
							setMainLoad={setMainLoad}
							isDarkMode={isDarkMode}
							setIsDarkMode={setIsDarkMode}
							defaultKey={defaultKey}
							setDefaultKey={setDefaultKey}
							setIsScroll={setIsScroll}
						/>
					</Route>

					<Route exact path='/portfolio'>
						<PortfolioPage
							setIsHeaderDark={setIsHeaderDark}
							isDarkMode={isDarkMode}
							setIsDarkMode={setIsDarkMode}
							defaultKey={defaultKey}
							setDefaultKey={setDefaultKey}
							setIsScroll={setIsScroll}
						/>
					</Route>

					<Route exact path={'/portfolio/:id'}>
						<PortfolioDetailPage setIsScroll={setIsScroll} />
					</Route>
					<Route exact path='/about'>
						<AboutPage
							setIsHeaderDark={setIsHeaderDark}
							isDarkMode={isDarkMode}
							setIsDarkMode={setIsDarkMode}
							setIsScroll={setIsScroll}
						/>
					</Route>
					{/* <Route exact path='/school'>
							<SchoolPage
								isDarkMode={isDarkMode}
								setIsDarkMode={setIsDarkMode}
								setIsScroll={setIsScroll}
							/>
						</Route> */}

					<Route exact path='/contact'>
						<Contact
							setIsHeaderDark={setIsHeaderDark}
							isDarkMode={isDarkMode}
							setIsDarkMode={setIsDarkMode}
							setIsScroll={setIsScroll}
						/>
					</Route>
					<Route exact path={'/project/:id'}>
						<RedirectPage />
					</Route>
					{/* <Redirect to='/' /> */}
				</Switch>
				<Footer
					isDarkMode={isDarkMode}
					setIsDarkMode={setIsDarkMode}
					isScroll={isScroll}
				/>
			</div>
		</Router>
	);
}
export default App;
