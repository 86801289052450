import React from 'react';
import './text-animation.css';

const TextAnimation = (props) => {
	return (
		<div>
			{props.line === 'l1' ? (
				<div
					style={props.sm ? { height: '15vh' } : { height: '22vh' }}
					className='main1'>
					<div className='con1' />
					<div className={props.line}>{props.text}</div>
				</div>
			) : (
				''
			)}

			{props.line === 'l2' ? (
				<div className='main2'>
					<div className='con2' />
					<div className={props.line}>{props.text}</div>
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default TextAnimation;
