import './mainLoaderStyle.scss';
import loaderVideo from '../../assets/logo for site.mp4';

const MainLoader = () => {
	return (
		<div className='loader-video'>
			<video
				autoPlay
				width='100%'
				muted={true}
				loop
				src={loaderVideo}
				type='video/mp4'></video>
		</div>
	);
};

export default MainLoader;
