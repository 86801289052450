import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';

import { redirect } from '../../api/redirect';

import MainLoader from '../../components/mainLoader/mainLoader';

const RedirectPage = () => {
	const [loading, setLoading] = useState(true);
	const { id } = useParams();
	useEffect(() => {
		const header = document.querySelector('header');
		header.style.display = 'none';
		try {
			setLoading(true);
			const getData = async () => {
				const res = await redirect(id);
				const data = await res.data;

				data.redirect_url
					? window.location.replace(data.redirect_url)
					: window.location.replace('http://tasnifgroup.uz/');
			};

			getData();
		} catch (error) {
			window.location.replace('http://tasnifgroup.uz/');
		}
	}, [id]);

	if (loading) return <MainLoader />;
};

export default RedirectPage;
