import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Behruz from '../../assets/images/Behruz 1.png';
import Akmal from '../../assets/images/Akmal 1.png';
import Davron from '../../assets/images/Davron aka 1.png';
import Dildora from '../../assets/images/Dildora 1.png';
import Diyor from '../../assets/images/Diyor 1.png';
import Laziz from '../../assets/images/Laziz aka 1.png';
import Lg from '../../assets/images/Lg 1.png';
import Mirshod from '../../assets/images/Mirshod 1.png';
import Shoxrux from '../../assets/images/Shoxruh 1.png';
import team from '../../assets/images/team-member.png';

import ProjectScroll from '../../components/projectsScroll/ProjectScroll';

import './AboutStyle.scss';

const AboutPage = ({ setIsDarkMode, setIsScroll }) => {
	useEffect(() => {
		setIsDarkMode(true);
		setIsScroll(false);
	}, [setIsDarkMode, setIsScroll]);

	return (
		<section className='about__page '>
			<div className='main-container'>
				<div className='bottom-content'>
					<p> Biz haqimizda</p>
				</div>
				<div className='about__page__content row'>
					<div className='about__page__content__logo'>
						<h3 className='about__page__content__logo-text'>
							Biz
							<br />
							haqimizda
						</h3>
					</div>
					<div
						className='about__page__content__text'
						style={{ width: '50%' }}>
						<span
							style={{
								color: '#fff!important',
								fontSize: '1.2rem',
							}}>
							Tasnif Group —<span> </span>
						</span>
						kompyuter grafikasi marketing sohasi bo'yicha ko'p
						yillik malakaga ega va o'z faoliyati davomida yetakchi
						kompaniyalar brendini dizayn sohasida rivojlantirishda
						katta yutuqlarga erishib kelayotgan dizayn agentligi.
						<br />
						<br /> Dizayn olamida 10 yildan ortiq faoliyat ko'rsatib
						kelayotgan professional dizayner, animator va
						operatorlardan iborat jamoa — har bir mijozga individual
						va o'zgacha yondashuv bilan yechim bera oladi!
					</div>
				</div>

				<div className='bottom-content'>
					<p> Raqamlarda kompaniyamiz </p>
				</div>
				<div className='about__page__numbers'>
					<div className='row'>
						<div className='col-md-4 col-4  about__page__numbers__title'>
							<h1>Kompaniya yutuqlari</h1>
						</div>
						<div className='col-md-4 col-4 about__page__numbers__main '>
							<h3>Loyihalar</h3>
							<h1>100+</h1>
							<p>
								Faoliyatimiz davomida biz respublika miqyosidagi
								50 dan ortiq loyihalar ustida ish olib
								borganmiz.
							</p>
						</div>
						<div className='col-md-4 col-4  about__page__numbers__main'>
							<h3>Xodimlar</h3>
							<h1>20+</h1>
							<p>
								Ayni damda agentligimizda 20dan ortiq doimiy
								ishlovchi xodimlar loyihalarimiz ustida ish olib
								borishmoqda.
							</p>
						</div>
						<div
							style={{ margin: '5vh 0' }}
							className='col-md-4 col-4   about__page__numbers__main about__page__numbers__main-empty  '></div>
						<div
							style={{ margin: '5vh 0' }}
							className='col-md-4 col-4    about__page__numbers__main'>
							<h3>Tajriba</h3>
							<h1>5+</h1>
							<p>
								Agentlik ochilganiga qariyb 2 yil bo'lganiga
								qaramay, xodimlarimizning barchasi o'z
								sohalarida kamida 5 yillik tajribaga egalar
							</p>
						</div>
						<div
							style={{ margin: '5vh 0' }}
							className='col-md-4 col-4   about__page__numbers__main'>
							<h3>Hamkorlar</h3>
							<h1>50+</h1>
							<p>
								Yuqorida ta'kidlab o'tilgan loyihalar davomida
								biz 30ga yaqin davlat hamda xususiy korxonalari
								bilan birgalikda ishlaganmiz.
							</p>
						</div>
					</div>
				</div>
				<div className='bottom-content'>
					<p> Bizning servislarimiz </p>
				</div>
				<div className='about__page__services'>
					<div className='row'>
						<div className='col-12 about__page__services__title'>
							<h1>Xizmatlarimiz</h1>
						</div>
						<div className='col-6 about__page__services__main'>
							<div className='service'>
								<h2>Graphic design</h2>
								<h2>Modeling</h2>
								<h2>Web design</h2>
								<h2>Arxitektura</h2>
							</div>
						</div>
						<div className='col-6 about__page__services__main'>
							<div className='service'>
								<h2>2d motion</h2>
								<h2>3d motion</h2>
								<h2>IT xizmatlar</h2>
								<h2>Video production</h2>
							</div>
						</div>
					</div>
				</div>
				<div className='bottom-content'>
					<p> Dream Team Tasnif </p>
				</div>
				<div className='about__page__team custom-cursor7'>
					<div className='about__page__team-item '>
						<img src={Laziz} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							Laziz <br /> Hamidov
						</h1>
						<p>art-direktor</p>
					</div>

					<div className='about__page__team-item '>
						<img src={Laziz} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							Abdulaziz <br /> Abbazov
						</h1>
						<p>Loyiha boshqaruvchisi</p>
					</div>
					<div className='about__page__team-item '>
						<img src={Lg} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							AbduLaziz <br /> Homidjonov
						</h1>
						<p>3d moushn dizayner</p>
					</div>
					<div className='about__page__team-item '>
						<img src={Davron} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							Davron <br /> Ergashev{' '}
						</h1>
						<p>grafik dizayner</p>
					</div>
					<div className='about__page__team-item '>
						<img src={Diyor} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							Diyor <br /> Rasulov
						</h1>
						<p>grafik dizayner</p>
					</div>
					<div className='about__page__team-item '>
						<img src={Mirshod} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							Mirshod <br /> Ibrohimov
						</h1>
						<p>UI/UX va grafik dizayner</p>
					</div>
					<div className='about__page__team-item '>
						<img src={Dildora} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							Dildora <br /> Shuxratova
						</h1>
						<p>SMM-menejer</p>
					</div>
					<div className='about__page__team-item '>
						<img src={Shoxrux} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							Shohrux <br /> Saparboyev
						</h1>
						<p>moushn dizayner</p>
					</div>
					<div className='about__page__team-item '>
						<img src={Akmal} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							Akmal <br /> To'xtayev
						</h1>
						<p>montaj ustasi</p>
					</div>
					<div className='about__page__team-item '>
						<img src={Behruz} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							Behruz <br /> Otamurodov
						</h1>
						<p>moushn dizayner</p>
					</div>
					<div className='about__page__team-item '>
						<img src={team} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							Shohista <br /> Qayumova
						</h1>
						<p>Loyiha boshqaruvchisi</p>
					</div>
					<div className='about__page__team-item '>
						<img src={team} width='30%' alt='team-members' />
						<div className='bg-content'></div>
						<h1>
							Laziz <br /> Hamidov
						</h1>
						<p>Lorem ipsum dolor sit amet.</p>
					</div>
				</div>
				<div className='bottom-content'>
					<p> Top proektlar </p>
				</div>
				<div className='about__page__projects '>
					<div className='about__page__projects__title'>
						<h1>Loyihalarimiz</h1>

						<button className='dark-button'>
							<Link to='/portfolio'>Portfolioni ko'rish</Link>
						</button>
					</div>
					<div className='custom-cursor4'>
						<ProjectScroll />
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutPage;
