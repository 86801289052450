import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { ContactAPi } from '../../api/contactApi';

import TextAnimation from '../../components/textAnimations';

import './contactStyle.scss';

const Contact = ({ setIsDarkMode, setIsScroll }) => {
	const [data, setData] = useState([]);
	const [checked, setChecked] = useState(false);
	const history = useHistory();

	useEffect(() => {
		setIsDarkMode(false);
	}, [setIsDarkMode]);
	useEffect(() => {
		setIsScroll(false);
	}, [setIsScroll]);

	const PostHandler = async (e) => {
		e.preventDefault();
		const res = await ContactAPi.postContact(data);
		if (res.status === 200) {
			history.push('/');
		}
	};
	return (
		<section className='contact main-container'>
			<div className='col-md-6 col-sm-12 contact-info'>
				<div className='title'>
					<h1>
						<TextAnimation
							sm={true}
							text='Biz bilan aloqa'
							line='l1'
						/>
					</h1>
				</div>
				<div className='all'>
					<h4>Umumiy ma’lumot uchun</h4>
					<p>
						tasnifgroup@gmail.com <br /> +998 93 804 16 66
					</p>
				</div>
				<div className='clients'>
					<h4>Mijozlar uchun</h4>
					<p>
						Abdulaziz Abbazov <br /> tasnifgroup@gmail.com <br />{' '}
						+998 91 164 72 65
					</p>
				</div>
				<div className='messengers'>
					<h4>Biz ijtimoiy tarmoqlarda</h4>
					<div className='messenger'>
						<a href='https://t.me/tasnifgroup'>Telegram</a>
					</div>
					<div className='messenger'>
						<a href='https://www.instagram.com/tasnifgroup'>
							Instagram
						</a>
					</div>
					<div className='messenger'>
						<a href='https://www.facebook.com/tasnifgroup'>
							Facebook
						</a>
					</div>
					<div className='messenger'>
						<a href='https://twitter.com/tasnifgroup'>Twitter</a>
					</div>
				</div>
			</div>
			<div className='col-md-1 col-sm-0'></div>
			<div className='col-md-5 col-sm-12 contact-forms '>
				<div className='contact-form'>
					<form onSubmit={(e) => PostHandler(e)}>
						<div className='form-group mt-2'>
							<label htmlFor='name'>Ism:</label>
							<input
								onChange={(e) =>
									setData({ ...data, name: e.target.value })
								}
								name='name'
								type='text'
								className='form-control'
								placeholder='Ism'
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='company'>Kompaniya nomi:</label>
							<input
								onChange={(e) =>
									setData({
										...data,
										company: e.target.value,
									})
								}
								name='company'
								type='text'
								className='form-control'
								placeholder='Company'
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='email'>Email:</label>
							<input
								onChange={(e) =>
									setData({ ...data, email: e.target.value })
								}
								name='email'
								type='email'
								className='form-control'
								placeholder='Email'
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='message'>Xabar:</label>
							<textarea
								onChange={(e) =>
									setData({
										...data,
										message: e.target.value,
									})
								}
								rows='4'
								name='message'
								type='email'
								className='form-control'
								placeholder='Sizning savolingiz'
							/>
						</div>
						<div className='form-group checkbox'>
							<input
								onChange={() => setChecked(!checked)}
								className='mx-2'
								type='checkbox'
								id='scales'
								name='scales'
							/>
							<label for='scales'>
								Kompaniya politikasining
								<a href='google.com'>
									<u className='mx-1'>
										shart va maxfiylik qoidalariga roziman
									</u>
								</a>
							</label>
						</div>

						<button
							disabled={!checked}
							type='submit'
							style={
								checked
									? { backgroundColor: 'rgba(0, 0, 0, 1)' }
									: {}
							}
							className='btn btn-primary'>
							Jo'natish
						</button>
					</form>
				</div>
			</div>
		</section>
	);
};

export default Contact;
