import Api from './api';

export const ProektApi = {
	getProekts: () => Api.get('/portfolio/projects/'),
	getProektById: (id) => Api.get(`/portfolio/project/${id}/`),
};

export const ProektScrollApi = {
	getProjects: () => Api.get('/portfolio/projects/featured/'),
};
