import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import { ProektApi } from '../../../api/proektApi';

import './portfolioDetail.scss';

const PortfolioDetailPage = ({ setIsScroll }) => {
	const idparam = useParams();
	const [project, setProject] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const footer = document.querySelector('footer');
		footer.style.marginTop = '0!important';
		setIsScroll(true);
		setLoading(true);
		const cursor = document.querySelector('#cursor-dot');
		const cursorOutline = document.querySelector('#cursor-dot-outline');
		cursor.style.visibility = 'hidden';
		cursorOutline.style.visibility = 'hidden';
		getProject();
	}, []);

	const getProject = async () => {
		const response = await ProektApi.getProektById(idparam.id);
		setProject(response.data);
		setLoading(false);
	};
	if (loading) {
		return (
			<div className='portfolio-detail'>
				<div className='portfolio-detail-description'>
					<div className='main-img-load col-12  '>
						<div className='loader'></div>
					</div>
				</div>

				<div className='main-img-load col-12 my-5  '>
					<div className='loader'></div>
				</div>
			</div>
		);
	}

	return (
		<div className='portfolio-detail main-container'>
			<div className='portfolio-detail-title-div'>
				<h1 className='portfolio-detail-title'>
					{/* <TextAnimation text={project?.title} line='l1' /> */}
					{project?.title}
				</h1>
			</div>
			<div className='portfolio-detail-description'>
				<h4>Proekt yo'nalishi</h4>
				<div>
					{project?.category?.map((category, i) => (
						<h4 key={i}>{category.name}</h4>
					))}
				</div>

				<div className='portfolio-detail-description-image my-3'>
					{project?.youtube_link !== null ? (
						<ReactPlayer
							url={`https://www.youtube.com/embed/${project?.youtube_link}`}
							loop={true}
							playing={true}
							width={'100%'}
							height={'100vh'}
							controls={true}
							scroll={true}
						/>
					) : project?.is_file ? (
						<video
							className='custom-cursor'
							autoPlay
							width='100%'
							loop
							src={project?.main_video?.video}
							type='video/mp4'>
							<source
								src={project?.main_video?.video}
								type='video/mp4'
							/>
						</video>
					) : (
						<img src={project?.main_photo?.image} alt='' />
					)}
				</div>
			</div>
			<div className='portfolio-detail-description-bottom'>
				<p>{project?.title}</p>
			</div>
			<div className='portfolio-detail-paragraph'>
				<div className='  portfolio-detail-paragraph-item'>
					<h2>{project?.main_description}</h2>
					<p id='main'>
						{project?.category.map((category, i) => (
							<span key={i}>
								{category.name}
								<br />
							</span>
						))}
					</p>
				</div>

				{project?.description ? (
					<div className='col-6 portfolio-detail-paragraph-item'>
						<h3>QISQACHA</h3>
						<p id='info'>{project?.description}</p>
					</div>
				) : (
					''
				)}
			</div>

			{project?.photo.map((photos, i) => (
				<div className='col-12 proekt-images' key={i}>
					<div className='image'>
						<img src={photos.image} alt='' />
					</div>
				</div>
			))}
			{project?.video.map((video, i) => (
				<div className='col-12 proekt-images' key={i}>
					<div className='image'>
						<video
							className='custom-cursor'
							autoPlay
							width='100%'
							muted={true}
							loop
							src={video.video}
							type='video/mp4'>
							<source src={video.video} type='video/mp4' />
						</video>
					</div>
				</div>
			))}
		</div>
	);
};

export default PortfolioDetailPage;
