import { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import { ProektApi } from '../../api/proektApi';

import TabComponent from '../../components/tab/TabComponent';

import './portfolioStyle.scss';

const PortfolioPage = ({ setIsDarkMode, defaultKey, setIsScroll }) => {
	const [proekts, setProekts] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [loading, setLoading] = useState(true);
	let data = [];

	useEffect(() => {
		setIsDarkMode(false);
	}, [setIsDarkMode]);
	useEffect(() => {
		setIsScroll(false);
		const cursor = document.querySelector('#cursor-dot');
		const cursorOutline = document.querySelector('#cursor-dot-outline');
		cursor.style.visibility = 'hidden';
		cursorOutline.style.visibility = 'hidden';
		setLoading(true);
		getProekts();
	}, []);
	const getProekts = async () => {
		const res = await ProektApi.getProekts();
		setProekts(res.data);
		setLoading(false);
		res.data.map((proekt) => {
			proekt.category.map((cat) => {
				if (cat.name.toLowerCase() === defaultKey.toLowerCase()) {
					data.push(proekt);
				}
			});
		});
		setFilteredData(data);
		setLoading(false);
	};
	const handleSelect = (key) => {
		setFilteredData([]);
		proekts.map((proekt, id) =>
			proekt.category.map((cat, id) => {
				if (key !== 'other') {
					if (cat.name.toLowerCase() === key.toLowerCase()) {
						data.push(proekt);
					}
				} else if (key === 'other') {
					if (
						cat.name.toLowerCase() !== 'graphic design' &&
						cat.name.toLowerCase() !== '3d motion' &&
						cat.name.toLowerCase() !== '2d motion' &&
						cat.name.toLowerCase() !== 'sculpture'
					) {
						data.push(proekt);
					}
				}
			})
		);

		setFilteredData(data);
	};
	if (loading) {
		return (
			<section className=' main-container portfolio-section '>
				{/* textanimation */}
				<h1 className='mobile-title' style={{ display: 'none' }}>
					Proektlar
				</h1>
				<Tabs
					defaultActiveKey={'All'}
					onSelect={handleSelect}
					className='mb-3'>
					<Tab
						eventKey='home'
						className='asd'
						disabled
						title='Proektlar'></Tab>
					<h1 className='small-title'>Proektlar</h1>

					<Tab eventKey='All' title='All'>
						<div className='container-fluid mb-5'>
							<div className='row'>
								<div className='main-img-load col-12  '>
									<div className='loader'></div>
								</div>
								<div className='medium-img-load col-5 w-30 mx-2'>
									<div className='loader'></div>
								</div>
								<div className='medium-img-load col-5 w-30 mx-2'>
									<div className='loader'></div>
								</div>
								<div className='small-img-load col-4'></div>
							</div>
						</div>
					</Tab>
					<Tab eventKey='Graphic Design' title='Graphic design'>
						<div className='container-fluid mb-5'>
							<div className='row'>
								<div className='main-img-load col-12  '>
									<div className='loader'></div>
								</div>
								<div className='medium-img-load col-5 w-30 mx-2'>
									<div className='loader'></div>
								</div>
								<div className='medium-img-load col-5 w-30 mx-2'>
									<div className='loader'></div>
								</div>
								<div className='small-img-load col-4'></div>
							</div>
						</div>
					</Tab>
					<Tab eventKey='3D Motion' title='3D Motion'>
						<div className='container-fluid mb-5'>
							<div className='row'>
								<div className='main-img-load col-12  '>
									<div className='loader'></div>
								</div>
								<div className='medium-img-load col-6 w-50'></div>
								<div className='small-img-load col-4'></div>
							</div>
						</div>
					</Tab>
					<Tab eventKey='2D Motion' title='2D Motion'>
						<div className='container-fluid mb-5'>
							<div className='row'>
								<div className='main-img-load col-12  '>
									<div className='loader'></div>
								</div>
								<div className='medium-img-load col-6 w-50'></div>
								<div className='small-img-load col-4'></div>
							</div>
						</div>
					</Tab>
					<Tab eventKey='Sculpture' title='Skulptura'>
						<div className='container-fluid mb-5'>
							<div className='row'>
								<div className='main-img-load col-12  '>
									<div className='loader'></div>
								</div>
								<div className='medium-img-load col-6 w-50'></div>
								<div className='small-img-load col-4'></div>
							</div>
						</div>
					</Tab>
				</Tabs>
			</section>
		);
	}
	return (
		<section className='portfolio-section main-container '>
			<h1 className='mobile-title ' style={{ display: 'none' }}>
				Proektlar
			</h1>
			<div className='custom-cursor7'>
				<Tabs
					defaultActiveKey='All'
					onSelect={handleSelect}
					className='mb-3 '>
					<Tab
						eventKey='home'
						className='asd'
						disabled
						title='Proektlar'></Tab>

					<h1 className='small-title'>Proektlar</h1>
					<Tab eventKey='All' title='All'>
						<TabComponent proekt={proekts} />
					</Tab>
					<Tab eventKey='Graphic Design' title='Graphic design'>
						<TabComponent proekt={filteredData} />
					</Tab>
					<Tab eventKey='3D Motion' title='3D Motion'>
						<TabComponent proekt={filteredData} />
					</Tab>
					<Tab eventKey='2D Motion' title='2D Motion'>
						<TabComponent proekt={filteredData} />
					</Tab>
					<Tab eventKey='Sculpture' title='Skulptura'>
						<TabComponent proekt={filteredData} />
					</Tab>
					{/* <Tab eventKey='other' title='Boshqalar'>
					<TabComponent proekt={filteredData} />
				</Tab> */}
				</Tabs>
			</div>
		</section>
	);
};

export default PortfolioPage;
