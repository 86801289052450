import React from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../api/api';

const ProjectCard = ({ data }) => {
	return (
		<div className='image-div'>
			<Link to={`/portfolio/${data?.id}`}>
				<div className='overlay'>
					<div className='text'>
						<h1>{data?.title}</h1>
						<p>
							{data?.category.map((cat, i) => {
								return <span key={i}>{cat.name}</span>;
							})}
						</p>
					</div>
				</div>

				<img
					src={BASE_URL + data?.main_photo.image}
					alt={data?.title}
				/>
			</Link>
		</div>
	);
};

export default ProjectCard;
