import './tabComponentStyle.scss';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BASE_URL } from '../../api/api';

const TabComponent = (props) => {
	return (
		<div className='container-fluid mb-5'>
			<div className='row '>
				{props?.proekt ? (
					props.proekt.map((project, key) => {
						return (
							<div
								key={key}
								className={
									props.home
										? key === 0
											? 'd-none'
											: key === 1 || key === 2
											? 'medium-img  col-6    my-3'
											: 'small-img col-4  my-3'
										: key === 0
										? 'main-img col-12'
										: key === 1 || key === 2
										? 'medium-img  col-6     my-3'
										: 'small-img col-4  my-3'
								}>
								<div className='medium-img-item '>
									<Link to={`/portfolio/${project?.id}`}>
										<LazyLoadImage
											alt={
												project?.main_photo_home_page
													?.title
											}
											className=' custom-none'
											height='70vh'
											width='100%'
											src={
												!props.home
													? project
															?.main_photo_home_page
															?.image
													: BASE_URL +
													  project
															?.main_photo_home_page
															?.image
											}
										/>
									</Link>
								</div>
								{key !== 0 ? (
									<>
										<h3>
											{project.title
												? project.title
												: project.main_photo_home_page
														.title}
										</h3>
										<p>
											{project.category.map((cat, i) => {
												return (
													<span key={i}>
														{cat.name}
													</span>
												);
											})}
										</p>
									</>
								) : (
									''
								)}
							</div>
						);
					})
				) : (
					<div className='main-img col-12'>
						<h3>''</h3>
					</div>
				)}
			</div>
		</div>
	);
};

export default TabComponent;
