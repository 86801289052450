import { useEffect, useRef, useState } from 'react';

import SwiperCore, { Navigation } from 'swiper';

import { BannerApi } from '../../api/bannerApi';
import { ProektScrollApi } from '../../api/proektApi';

import AOS from 'aos';
import TabComponent from '../../components/tab/TabComponent';

import 'react-multi-carousel/lib/styles.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import './homeStyle.scss';
import { BASE_URL } from '../../api/api';

const Home = ({
	setIsDarkMode,
	setDefaultKey,
	category,
	setCategory,
	categoryGraphic,
	category2d,
	category3d,
	categorySculpture,
	setIsScroll,
	setIsHeaderDark,
}) => {
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [mainVideo, setMainVideo] = useState('');
	const [projects, setProjects] = useState([]);
	const [loading, setLoading] = useState(true);

	const elVideo = useRef();

	useEffect(() => {
		setIsHeaderDark(true);
		setIsDarkMode(false);
		SwiperCore.use([Navigation]);
		setIsScroll(false);

		const el = document.getElementById('video');

		if (el !== null) {
			if (el?.requestFullscreen) {
				setIsFullScreen(true);
				el.muted = true;
			} else {
				setIsFullScreen(false);
				el.muted = true;
				AOS.init({
					duration: 1200,
				});
			}
		}

		const getData = async () => {
			try {
				const data = await BannerApi.getBanner();

				setMainVideo(data.data[0].videoUrl);
				const res = await ProektScrollApi.getProjects();
				setProjects(res.data);
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		};
		getData();
	}, []);

	const fullScreenVideo = () => {
		if (elVideo.current.requestFullscreen) {
			setIsFullScreen(true);
			elVideo.current.requestFullscreen();
			elVideo.current.muted = false;
		} else {
			setIsFullScreen(false);
			elVideo.current.muted = true;
		}

		if (elVideo.current.mozRequestFullScreen) {
			elVideo.current.mozRequestFullScreen();
			elVideo.current.muted = false;
			setIsFullScreen(true);
		}
		if (elVideo.current.webkitRequestFullscreen) {
			elVideo.current.webkitRequestFullscreen();
			elVideo.current.muted = false;
			setIsFullScreen(true);
		}

		if (elVideo.current.msRequestFullscreen) {
			elVideo.current.msRequestFullscreen();
			elVideo.current.muted = false;
			setIsFullScreen(true);
		}
	};

	return (
		<main className='home '>
			<div className='home-top'>
				<div className='row  '>
					<section
						className='main-video col-12  '
						onClick={() => fullScreenVideo()}>
						<video
							ref={elVideo}
							style={{ cursor: 'pointer' }}
							id='video'
							className='custom-cursor'
							autoPlay={true}
							width='100%'
							muted={true}
							loop
							src={`${BASE_URL}mediafiles/banners/showreel_latest_sdss6M4.mp4`}
							type='video/mp4'>
							<source src={mainVideo} type='video/mp4' />
						</video>

						<div className='border-bt'></div>
					</section>
					{/* 
					<section className='col-6 col-md-6 col-sm-12  second__screen'>
						<h2>
							Kuchli professionallardan tashkil topgan
							<span> "TASNIF Group" </span>
							jamoasi — brending, 2D va 3D grafik animatsiya
							sohasida xizmat ko'rsatadi. Siz bilan hamkorlikdan
							hamisha mamnunmiz!
						</h2>
						<button className='white-button'>
							<Link to='/portfolio'>Portfolioni ko'rish</Link>
						</button>
					</section> */}
				</div>
			</div>

			{/* <CustomButton title="Barcha proektlarni ko'rish" /> */}

			<section className='main-container'>
				{/* categories */}
				{/* <section style={{ margin: '0 auto 20vh' }}>
					<button className='white-button'>
						<Link to='/portfolio'>Barcha loyihalarni ko'rish</Link>
					</button>
				</section>
				<div className='category__screen-title'>
					<Link to='/portfolio'>
						<h4
							style={{ marginLeft: '0' }}
							onClick={() => setDefaultKey('Graphic Design')}
							className='category__screen-title-1 category__screen-title-active'>
							GRAPHIC DESIGN
						</h4>
					</Link>
					<Link to='/portfolio'>
						<h4
							onClick={() => setDefaultKey('2D Motion')}
							className='category__screen-title-2'>
							2D MOTION
						</h4>
					</Link>
					<Link to='/portfolio'>
						<h4
							onClick={() => setDefaultKey('3D Motion')}
							className='category__screen-title-3'>
							3D MOTION
						</h4>
					</Link>
					<Link to='/portfolio'>
						<h4
							onClick={() => setDefaultKey('Sculpture')}
							className='category__screen-title-4'>
							SCULPTURE
						</h4>
					</Link>
					<Link to='/portfolio'>
						<h4
							onClick={() => setDefaultKey('other')}
							className='category__screen-title-5'>
							BOSHQALAR
						</h4>
					</Link>
				</div>
				<section className=' category__screen '>
					<div className='category__screen-div custom-cursor2'>
						{category?.map((item, i) => {
							return (
								<div
									key={i}
									className='category__screen-images'>
									<img
										className='category__screen-image'
										src={item.image}
										alt=''
									/>
								</div>
							);
						})}
					</div>
				</section> */}

				<div className='container2 '>
					<div className='row'>
						{/* clients */}
						{/* <section className='col-12 partners__screen my-5'>
							<div className=''>
								<div className='block-title my-5'>
									<h2 className='block-title__title'>
										Bizning <br /> hamkorlarimiz
									</h2>
								</div>

								<Swiper
									spaceBetween={50}
									slidesPerView={8.5}
									loop={true}
									speed={1000}
									autoplay={{
										delay: 1000,
									}}>
									{partners !== []
										? partners.map((partner, i) => {
												return (
													<SwiperSlide
														className='partner-div'
														key={i}>
														<img
															width='120'
															height='80'
															src={partner.logo}
															alt={partner.name}
														/>
													</SwiperSlide>
												);
										  })
										: ''}
								</Swiper>
							</div>
						</section> */}

						{loading && (
							<div className='container-fluid mb-5'>
								<div className='row'>
									<div className='medium-img-load col-5  mx-2'>
										<div className='loader'></div>
									</div>
									<div className='medium-img-load col-5  mx-2'>
										<div className='loader'></div>
									</div>
									<div className='medium-img-load col-5  mx-2'>
										<div className='loader'></div>
									</div>
									<div className='medium-img-load col-5  mx-2'>
										<div className='loader'></div>
									</div>
								</div>
							</div>
						)}

						<section
							className='top-projects-section'
							style={{ marginTop: '120px' }}>
							<h3>Bizning ishlarimiz</h3>
							<hr />

							{!loading && (
								<div className='projects'>
									<div className='project custom-cursor6'>
										<TabComponent
											proekt={projects}
											home={true}
										/>
									</div>
								</div>
							)}
						</section>

						{/* <section className='projects__screen my-5 '>
							<div className='block-title my-5'>
								<h2 className='block-title__title'>
									Loyihalarimiz
								</h2>
							</div>
							<div className='custom-cursor4'>
								<ProjectScroll />
							</div>
						</section> */}
					</div>
				</div>
			</section>
		</main>
	);
};

export default Home;
